import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'

const AppCompanyPartnersBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CompanyPartnersBannerSection {
    height: 70vh;
    /* background-image: url(/img/raindrop_partners_Banner.png);
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; */
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 96px 50px 96px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1390px) {
      padding: 80px 30px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .CompanyPartnersHeading {
    margin-top: 55px;
    font-size: 40px;
    /* padding-top: 15%; */
    @media (max-width: 1650px) {
      padding-top: 0%;
      margin-top: 55px;
    }
    @media (max-width: 1090px) {
      padding-top: 35px;
    }
  }
  .NewsBannerImageSection {
    width: 50%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .companyPartnersBannerImage {
    width: 100%;
    height: auto;
  }
  .CustomerBannerPara {
    margin: 40px 0px 0px 0px;
    @media (max-width: 1650px) {
      /* margin-right: 20%; */
    }
    @media (max-width: 1090px) {
      /* margin-right: 20px; */
    }
    @media (max-width: 1920px) {
      /* margin-right: 40%; */
    }
  }
  .NewsBannerImageSection {
    width: 45%;
  }
  .CompanyPartnersBannerContentSection {
    width: 50%;
    @media (max-width: 1920px) {
      width: 45%;
    }
  }
`

export const AppCompanyPartnersBanner = () => {
  return (
    <AppCompanyPartnersBannerWapper>
      <div className="CompanyPartnersBannerSection">
        <div className="CompanyPartnersBannerContentSection">
          <ReUseHOneTag Heading="Meet Our Partners" HeadingStyle="CompanyPartnersHeading" />
          <ReUsePtag
            para="Raindrop and our partners deliver digital procurement and advisory solutions with rapid speed to value attainment."
            paraStyle="CustomerBannerPara "
          />
        </div>
        <div className="NewsBannerImageSection">
          <ReuseImgTag
            ImageStyle="companyPartnersBannerImage"
            ImagePath="https://storage.googleapis.com/raindroppublic/website_data/Raindrop-Partners-banner.png"
            AltName="Company-partners-Banner-image"
          />
        </div>
      </div>
    </AppCompanyPartnersBannerWapper>
  )
}
